import { render, staticRenderFns } from "./ProcessList.vue?vue&type=template&id=60c9af06&scoped=true"
import script from "./ProcessList.vue?vue&type=script&lang=js"
export * from "./ProcessList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60c9af06",
  null
  
)

export default component.exports